import React from "react";
import { Helmet } from "react-helmet"





const Test = () => (

  <section className="landing">
    <Helmet>
      <meta name="ahrefs-site-verification" content="1750638eb8c1a510a359f1ec03f28cf744c4a3df7b930b9555da4cfd4dbded1b" />
      <meta name="robots" content="noindex, nofollow" />
      <title>Test page</title>
      <meta name="description" content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book" />
      <meta name="keywords" content="Test page" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cryptocurrency Exchange Script to Start a Bitcoin Exchange" />
      <meta property="og:description" content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book" />
      <meta property="og:url" content="https://www.coinsclone.com/" />
      <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/cryptocurrency-exchange-clone-script.png" />
      <meta property="og:site_name" content="Coinsclone" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Coinsclone" />
      <meta name="twitter:creator" content="@Coinsclone" />
      <meta name="twitter:description" content="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book" />
      <meta name="twitter:title" content="Cryptocurrency Exchange Script to Start a Bitcoin Exchange" />
      <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/cryptocurrency-exchange-clone-script.png" />
      <meta name="msvalidate.01" content="4C6D3C657B662E541A5B7866529A7123" />
      <link rel="canonical" href="https://www.coinsclone.com/test/" />
     
    </Helmet>
    <h1>Test</h1>
  </section>
)

export default Test
